import React from "react";
import "swiper/swiper.scss";
import Swiper from "react-id-swiper";

// import LogoAvon from "../../images/partners-logo/logo_avon.inline.svg";
// import LogoKolekcjaMadelskich from "../../images/partners-logo/logo_kolekcja_madelskich.inline.svg";
// import LogoMetropoliaPoznan from "../../images/partners-logo/logo_metropolia_poznan.inline.svg";
// import LogoPoznanArtWeek from "../../images/partners-logo/logo_poznan_art_week.inline.svg";
// import LogoUAP from "../../images/partners-logo/logo_uniwersytet_artystyczny_w_poznaniu.inline.svg";
// import LogoVMR from "../../images/partners-logo/logo_vmr.inline.svg";
// import LogoYES from "../../images/partners-logo/logo_yes.inline.svg";
// import LogoBP from "../../images/partners-logo/logo_bp.inline.svg";
// import LogoZielonePogotowie from "../../images/partners-logo/logo_zielone_pogotowie.inline.svg";
// import LogoSWPS from "../../images/partners-logo/logo_swps.inline.svg";
// import LogoBudimex from "../../images/partners-logo/logo_budimex.inline.svg";
// import LogoAmica from "../../images/partners-logo/logo_amica.inline.svg";

import ImgLogoYES from "../../images/partners-logo/logo_yes.svg";
import ImgLogoZielonePogotowie from "../../images/partners-logo/logo_zielone_pogotowie.svg";
import ImgLogoAvon from "../../images/partners-logo/logo_avon.svg";
import ImgLogoBP from "../../images/partners-logo/logo_bp.svg";
import ImgLogoMetropoliaPoznan from "../../images/partners-logo/logo_metropolia_poznan.svg";
import ImgLogoSWPS from "../../images/partners-logo/logo_swps.svg";
import ImgLogoPoznanArtWeek from "../../images/partners-logo/logo_poznan_art_week.svg";
import ImgLogoAmica from "../../images/partners-logo/logo_amica.svg";
import ImgLogoKolekcjaMadelskich from "../../images/partners-logo/logo_kolekcja_madelskich.svg";
import ImgLogoBudimex from "../../images/partners-logo/logo_budimex.svg";
import ImgLogoUAP from "../../images/partners-logo/logo_uniwersytet_artystyczny_w_poznaniu.svg";

import "./customers.scss";
import { InView } from "react-intersection-observer";
import Section from "../air-section/air-section";

interface Props {}
const customerItems = [
  {
    img: ImgLogoYES,
    alt: "Logo Yes",
  },
  {
    img: ImgLogoZielonePogotowie,
    alt: "Logo Zielone Pogotowie",
  },
  {
    img: ImgLogoAvon,
    alt: "Logo Avon",
  },
  {
    img: ImgLogoBP,
    alt: "Logo BP",
  },
  {
    img: ImgLogoMetropoliaPoznan,
    alt: "Logo Metropolia Poznań",
  },
  {
    img: ImgLogoSWPS,
    alt: "Logo SWPS",
  },
  {
    img: ImgLogoBudimex,
    alt: "Logo Budimex",
  },
  {
    img: ImgLogoKolekcjaMadelskich,
    alt: "Logo Madelski Colleciton",
  },
  {
    img: ImgLogoPoznanArtWeek,
    alt: "Logo Poznań Art Week",
  },
  {
    img: ImgLogoAmica,
    alt: "Logo Amica",
  },
  {
    img: ImgLogoUAP,
    alt: "Logo UAP",
  },
];

// const customerItems = [
//   {
//     name: "LogoAvon",
//     src: <LogoAvon title="Logo firmy Avon" />,
//   },
//   {
//     name: "LogoBudimex",
//     src: <LogoBudimex title="Logo Budimex" />,
//   },
//   {
//     name: "LogoMetropoliaPoznan",
//     src: <LogoMetropoliaPoznan title="Logo Metropolia Poznań" />,
//   },
//   {
//     name: "LogoAmica",
//     src: <LogoAmica title="Logo Amica" />,
//   },
//   {
//     name: "LogoUAP",
//     src: <LogoUAP title="Logo Uniwaersytetu Artystycznego w Poznaniu" />,
//   },
//   {
//     name: "LogoSWPS",
//     src: <LogoSWPS title="Logo SWPS" />,
//   },
//   {
//     name: "LogoBP",
//     src: <LogoBP title="Logo BP" />,
//   },
//   {
//     name: "LogoYES",
//     src: <LogoYES title="Logo YES Biżuteria" />,
//   },
//   {
//     name: "LogoVMR",
//     src: <LogoVMR title="Logo VMR" />,
//   },
//   {
//     name: "LogoKolekcjaMadelskich",
//     src: <LogoKolekcjaMadelskich title="Logo Kolekcja Madelskich" />,
//   },
//   {
//     name: "LogoPoznanArtWeek",
//     src: <LogoPoznanArtWeek title="Logo Poznań Art Week" />,
//   },
//   {
//     name: "LogoZielonePogotowie",
//     src: <LogoZielonePogotowie title="Logo Zielone Pogotowie" />,
//   },
// ];

interface Props {}
const Customers = (props: Props) => {
  const swiperParams = {
    direction: "horizontal",
    speed: 500,
    height: 100,
    loop: true,
    centeredSlides: true,
    hashNavigation: false,
    allowTouchMove: false,
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
      // when window width is >= 1200px
      1200: {
        slidesPerView: 7,
        spaceBetween: 40,
      },
    },
  };

  return (
    <Section className="customers-wrapper">
      <InView>
        {({ inView, ref, entry }) => (
          <div ref={ref}>
            {inView && (
              <div className="swiper-container">
                <Swiper {...swiperParams}>
                  {customerItems.map((item, index) => {
                    return (
                      // <div key={index} className="swiper-slide">
                      //   {item.src}
                      // </div>
                      <div key={index} className="swiper-slide">
                        <img src={item.img} alt={item.alt} />
                      </div>
                    );
                  })}
                </Swiper>
              </div>
            )}
          </div>
        )}
      </InView>
    </Section>
  );
};

export default React.memo(Customers);
