/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Link } from "gatsby";
import { Config } from "../../../config";
import "./button.scss";
import ALink from "../a-link/a-link";
import { type } from "os";

interface Props {
  language: string;
  to: string;
  type: "inversed" | "default";
  color: "primary" | "default";
  children: any;
  title?: string;
  className?: string;
  activeClassName?: string;
  activeStyle?: { [key: string]: any };
}

class Button extends React.Component {
  static defaultProps = {
    type: "default",
    color: "default",
  };

  constructor(public props: Props) {
    super(props);
  }
  filteredProps() {
    let className = `button button-${this.props.color} button-${this.props.type}`;

    const filteredProps = { ...this.props };
    delete filteredProps.type;
    delete filteredProps.children;
    if (typeof filteredProps.className === "string") {
      className = filteredProps.className + " " + className;
    }
    filteredProps.className = className;
    return filteredProps;
  }
  render() {
    return <ALink {...this.filteredProps()}>{this.props.children}</ALink>;
  }
}

export default Button;
